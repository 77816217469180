import React, {useEffect, useRef, useState} from 'react';
import {DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel} from '@mui/x-data-grid';
import {fetchConsignmentsPage, fetchConsignmentStatuses,} from '../../api/endpoints';
import {SingleSelectMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import ConsignmentDto from '../../interfaces/ConsignmentDto';
import {isRight} from 'fp-ts/lib/Either';
import Modal from 'react-modal';
import AdminConsignmentModal from './AdminConsignmentModal';
import {formatDateToSlash} from '../../utils/DateUtils/DateUtils';


interface RowData {
    id: number;
    eventName: string,
    section: string;
    row: string;
    quantity: number;
    price: number;
    status: boolean;
}

const AdminConsignments: React.FC = () => {
    const statusRef = useRef<HTMLDivElement>(null);

    const [loadingConsignments, setLoadingConsignments] = useState(true);
    const [statusOptions, setStatusOptions] = useState<string[]>([]);
    const [filterQuery, setFilterQuery] = useState('');
    const [rows, setRows] = useState<ConsignmentDto[]>([]);
    const [lastSearched, setLastSearched] = useState('');
    const [totalRows, setTotalRows] = useState<number>(0);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedConsignment, setSelectedConsignment] = useState<ConsignmentDto | null>(null);
    const [activeStatusPicker, setActiveStatusPicker] = useState(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: "updatedAt",
            sort: "desc"
        }
    ]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });

    const statusFiltered = selectedStatus !== '';

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 80, disableColumnMenu: true },
        { field: 'createdAt', headerName: 'Submitted', width: 120, disableColumnMenu: true, renderCell: (params) => (formatDateToSlash(params.value)) },
        { field: 'userFirstName', headerName: 'First Name', width: 120, disableColumnMenu: true },
        { field: 'userLastName', headerName: 'Last Name', width: 120, disableColumnMenu: true },
        { field: 'eventName', headerName: 'Event', width: 300, disableColumnMenu: true },
        { field: 'eventDate', headerName: 'Event Date', width: 120, disableColumnMenu: true, renderCell: (params) => (formatDateToSlash(params.value)) },
        { field: 'status', headerName: 'Status', width: 100, disableColumnMenu: true }
    ];

    const fetchData = async (search: string, paginationModel: GridPaginationModel, sortModel: GridSortModel) => {
        setLoadingConsignments(true);
        const page = paginationModel.page;
        const pageSize = paginationModel.pageSize;
        const sortBy = sortModel[0]?.field || "updatedAt";
        const order = sortModel[0]?.sort || "desc";
        const ordersDataResponseEither = await fetchConsignmentsPage(page, pageSize, search, selectedStatus, sortBy, order);
        if (isRight(ordersDataResponseEither)) {
            const { content, total, pageable } = ordersDataResponseEither.right;
            setRows(content);
            setTotalRows(total);
            setLoadingConsignments(false);
        } else {
            console.error(ordersDataResponseEither.left)
        }
    };

    const refetchData = async () => {
        fetchData(lastSearched, paginationModel, sortModel);
    }

    useEffect(() => {
        const fetchStatusOptions = async () => {
            const statusOptionsResponseEither = await fetchConsignmentStatuses();
            if (isRight(statusOptionsResponseEither)) {
                setStatusOptions(statusOptionsResponseEither.right);
            } else {
                console.error(statusOptionsResponseEither.left)
            }

        }

        const handleClickOutsideStatusPicker = (e: MouseEvent) => {
            if (statusRef.current && e.target instanceof Node && !statusRef.current.contains(e.target)) {
                setActiveStatusPicker(false);
            }
        };

        fetchStatusOptions();
        document.addEventListener('mousedown', handleClickOutsideStatusPicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideStatusPicker);
        };
    }, []);

    useEffect(() => {
        fetchData(lastSearched, paginationModel, sortModel);
    }, [paginationModel, sortModel]);

    useEffect(() => {
        fetchData(lastSearched, paginationModel, sortModel);
    }, [selectedStatus]);

    const handleRowClick = (params: GridRowParams) => {
        setSelectedConsignment(params.row as ConsignmentDto);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedConsignment(null);
    };

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterQuery(e.target.value);
    };

    const handleFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetchData(filterQuery, paginationModel, sortModel);
        setLastSearched(filterQuery);
    }

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPaginationModel(model);
    };

    const handleSortModelChange = (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
    };

    return (
        <div className='card card-top' style={{ minHeight: 500, width: '100%' }} >
            <div className='text-l'>Consignments</div>
            <div className='card-item'>
                <form onSubmit={handleFilterSubmit} className='card-item'>
                    <div className='row'>
                        <input type="text" value={filterQuery} onChange={handleQueryChange}
                            placeholder="Type to filter by id, first name, last name"
                            className='admin-search'
                        />
                        <button className='admin-action-button' type="submit">Filter</button>
                        <div className='gap' />
                        <div className='relative' ref={statusRef}>
                            <button className={`admin-button ${statusFiltered && 'admin-filtered'}`} onClick={(() => setActiveStatusPicker(!activeStatusPicker))}>{statusFiltered ? selectedStatus : "Consignment Status"}</button>
                            {activeStatusPicker &&
                                <SingleSelectMenu
                                    options={statusOptions}
                                    selectedOption={selectedStatus}
                                    setSelectedOption={setSelectedStatus}
                                    closeMenu={() => setActiveStatusPicker(false)}
                                    mustSelect={false}
                                    rightAligned={true}
                                />
                            }
                        </div>
                    </div>
                </form>
                <div className='row'>
                </div>
            </div>
            {loadingConsignments ?
                <div className="loading-spinner" />
                :
                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    rowCount={totalRows}
                    pageSizeOptions={[5, 10, 20]}
                    onRowClick={handleRowClick}
                    className='admin-data-grid'
                />
            }
            <Modal isOpen={openDialog} onRequestClose={handleCloseDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '1000px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '40px',
                    padding: '40px',
                    borderRadius: '15px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                {selectedConsignment && (
                    <AdminConsignmentModal consignment={selectedConsignment} refetchData={refetchData} />
                )}
            </Modal>
        </div>
    )
}

export default AdminConsignments;
