import {AxiosResponse} from 'axios';
import Performer from "../interfaces/Performer";
import Event from "../interfaces/Event";
import Listing from "../interfaces/Listing";
import LoginResponse from "../interfaces/LoginResponse";
import ErrorResponse from "../interfaces/ErrorResponse";
import ResponseSuccess from "../interfaces/ResponseSuccess";
import {Either, left, right} from 'fp-ts/lib/Either';
import Venue from "../interfaces/Venue";
import Transaction from "../interfaces/Transaction";
import SearchResult from "../interfaces/SearchResult";
import PurchaseOrder from "../interfaces/PurchaseOrder";
import DropdownMenu from "../interfaces/DropdownMenu";
import SuggestionCategory from "../interfaces/SuggestionCategory";
import ConsignmentSubmission from "../interfaces/ConsignmentSubmission";
import ConsignmentDto from "../interfaces/ConsignmentDto";
import Page from "../interfaces/Page";
import ExpandedOrder from "../interfaces/ExpandedOrder";
import ConsignmentsComplete from "../interfaces/ConsignmentsComplete";
import UserDto from "../interfaces/UserDto";
import MarkupGeneral from "../interfaces/MarkupGeneral";
import MarkupVenue from "../interfaces/MarkupVenue";
import MarkupEvent from "../interfaces/MarkupEvent";
import ShippingPricing from "../interfaces/ShippingPricing";
import Category from "../interfaces/Category";
import CouponVerificationDto from "../interfaces/CouponVerificationDto";
import CouponDto from "../interfaces/CouponDto";
import ListingSource from "../interfaces/ListingSource";
import CustomEventDto from "../interfaces/CustomEventDto";
import GeneralSettingBoolean from "../interfaces/GeneralSettingBoolean";
import GeneralSettingNumber from "../interfaces/GeneralSettingNumber";
import CouponStatsDto from "../interfaces/CouponStatsDto";
import apiClient from "./apiClient";
import apiClientAuth from "./apiClientAuth";


const handleResponsePromise = async (responsePromise: Promise<AxiosResponse<any, any>>) => {
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const fetchAdminWriteImagesSASToken = async (): Promise<Either<string, string>> => {
    const responsePromise = apiClientAuth.get("/admin/write/images");
    return handleResponsePromise(responsePromise);
}

export const fetchAdminWriteEticketsSASToken = async (): Promise<Either<string, string>> => {
    const responsePromise = apiClientAuth.get("/admin/write/etickets");
    return handleResponsePromise(responsePromise);
}

export const fetchReadEticketsSASToken = async (orderId: number, blobPath: string): Promise<Either<string, string>> => {
    const config = {
        params: { orderId: orderId, blobPath: blobPath }
    };
    const responsePromise = apiClientAuth.get("/read/etickets", config);
    return handleResponsePromise(responsePromise);
}

export const fetchSearchResultByQuery = async (query: string): Promise<Either<string, SearchResult>> => {
    const config = {
        params: { query: encodeURIComponent(query) }
    };
    const responsePromise = apiClient.get("/api/search/query", config);
    return handleResponsePromise(responsePromise);
}


export const fetchPerformersByCategory = async (categoryId: string): Promise<Either<string, Performer[]>> => {
    const config = {
        params: { categoryId: categoryId }
    };
    const responsePromise = apiClient.get("/api/performers/category", config);
    return handleResponsePromise(responsePromise);
}


export const fetchPerformersByQuery = async (query: string, includeCustom: boolean): Promise<Either<string, Performer[]>> => {
    const config = {
        params: { query: encodeURIComponent(query), includeCustom: includeCustom }
    };
    const responsePromise = apiClient.get("/api/search/performers/query", config);
    return handleResponsePromise(responsePromise);
}


export const fetchPerformerById = async (performerId: string): Promise<Either<string, Performer>> => {
    const config = {
        params: { performerId: encodeURIComponent(performerId) }
    };
    const responsePromise = apiClient.get("/api/performers/id", config);
    return handleResponsePromise(responsePromise);
}


export const fetchEventsByPerformer = async (performerId: string): Promise<Either<string, Event[]>> => {
    const config = {
        params: { performerId: performerId }
    };
    const responsePromise = apiClient.get("/api/events/performer", config);
    return handleResponsePromise(responsePromise);
}


export const fetchEventsByVenue = async (venueId: string): Promise<Either<string, Event[]>> => {
    const config = {
        params: { venueId: venueId }
    };
    const responsePromise = apiClient.get("/api/events/venue", config);
    return handleResponsePromise(responsePromise);
}


export const fetchEventsByQuery = async (query: string, includeCustom: boolean): Promise<Either<string, Event[]>> => {
    const config = {
        params: { query: encodeURIComponent(query), includeCustom: includeCustom }
    };
    const responsePromise = apiClient.get("/api/search/events/query", config);
    return handleResponsePromise(responsePromise);
}


export const fetchEventById = async (eventId: number, listingSource: ListingSource): Promise<Either<string, Event>> => {
    const config = {
        params: { eventId: encodeURIComponent(eventId), listingSource: listingSource }
    };
    const responsePromise = apiClient.get("/api/events/id", config);
    return handleResponsePromise(responsePromise);
}


export const fetchListingsByEvent = async (eventId: number, listingSource: ListingSource): Promise<Either<string, Listing[]>> => {
    const config = {
        params: { eventId: eventId, listingSource: listingSource }
    };
    const responsePromise = apiClient.get("/api/listings/event", config);
    return handleResponsePromise(responsePromise);
}


export const fetchListingById = async (ticketGroupId: string, eventId: number, listingSource: ListingSource): Promise<Either<string, Listing>> => {
    const config = {
        params: { ticketGroupId: encodeURIComponent(ticketGroupId), eventId: eventId, listingSource: listingSource }
    };
    const responsePromise = apiClient.get("/api/listings/id", config);
    return handleResponsePromise(responsePromise);
}


export const fetchVenuesByQuery = async (query: string): Promise<Either<string, Venue[]>> => {
    const config = {
        params: { query: encodeURIComponent(query) }
    };
    const responsePromise = apiClient.get("/api/search/venues/query", config);
    return handleResponsePromise(responsePromise);
}


export const fetchVenueById = async (venueId: string): Promise<Either<string, Venue>> => {
    const config = {
        params: { venueId: encodeURIComponent(venueId) }
    };
    const responsePromise = apiClient.get("/api/venues/id", config);
    return handleResponsePromise(responsePromise);
}


export const fetchAllCategories = async (): Promise<Either<string, Category[]>> => {
    const responsePromise = apiClient.get("/api/category/all");
    return handleResponsePromise(responsePromise);
}


export const fetchUserInfo = async (): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.get("/user/info");
    return handleResponsePromise(responsePromise);
}


export const fetchUserRewards = async (): Promise<Either<string, number>> => {
    const responsePromise = apiClientAuth.get("/user/rewards");
    return handleResponsePromise(responsePromise);
}


export const changeUserInfo = async (userInfoChangeDto: { userDto: UserDto, password: string }): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.post("/user/info-change", userInfoChangeDto);
    return handleResponsePromise(responsePromise);
}


export const changeUserPassword = async (passwordChangeDto: { oldPassword: string, newPassword: string }): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.post("/user/password-change", passwordChangeDto);
    return handleResponsePromise(responsePromise);
}


export const tryLogIn = async (email: string, password: string, rememberMe: boolean): Promise<Either<string, LoginResponse>> => {
    const userData = {
        email: email,
        password: password,
        rememberMe: rememberMe
    };
    const responsePromise = apiClientAuth.post("/auth/login", userData);
    return handleResponsePromise(responsePromise);
}


export const logOut = async (): Promise<Either<string, String>> => {
    const responsePromise = apiClient.post("auth/logout", {})
    return handleResponsePromise(responsePromise);
}


export const trySignUp = async (email: string, password: string, firstName: string, lastName: string, heardAbout: string): Promise<Either<ErrorResponse, ResponseSuccess>> => {
    const userData = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        heardAbout: heardAbout
    };
    const responsePromise = apiClient.post("/auth/signup", userData);
    return handleResponsePromise(responsePromise);
}


export const fetchAccessToken = async (): Promise<Either<string, LoginResponse>> => {
    const responsePromise = apiClient.post("/auth/refresh-token");
    return handleResponsePromise(responsePromise);
}


export const triggerPasswordResetEmail = async (userEmail: string): Promise<Either<ErrorResponse, LoginResponse>> => {
    const requestData = { email: userEmail };
    const responsePromise = apiClient.post("/auth/trigger-reset", requestData);
    return handleResponsePromise(responsePromise);
}


export const actuallyResetPassword = async (newPassword: string, resetToken: string): Promise<Either<ErrorResponse, LoginResponse>> => {
    const requestData = { newPassword: newPassword, jwtToken: resetToken };
    const responsePromise = apiClientAuth.post("/auth/actually-reset", requestData);
    return handleResponsePromise(responsePromise);
}


export const triggerUserVerification = async (verificationToken: string): Promise<Either<ErrorResponse, boolean>> => {
    const requestData = { confirmationToken: verificationToken };
    const responsePromise = apiClient.post("/auth/verify", requestData);
    return handleResponsePromise(responsePromise);
}


export const applyCoupon = async (coupon: CouponVerificationDto): Promise<Either<string, string>> => {
    const responsePromise = apiClientAuth.post("/coupon/discount", coupon);
    return handleResponsePromise(responsePromise);
}


export const processTransaction = async (transaction: Transaction): Promise<Either<string, PurchaseOrder>> => {
    const responsePromise = apiClientAuth.post("/process-transaction", transaction);
    return handleResponsePromise(responsePromise);
}


export const fetchAccountOrders = async (): Promise<Either<string, PurchaseOrder[]>> => {
    const responsePromise = apiClientAuth.get("/user/orders");
    return handleResponsePromise(responsePromise);
}


export const fetchDropdownMenu = async (): Promise<Either<string, DropdownMenu[]>> => {
    const responsePromise = apiClient.get('/dropdown/get');
    return handleResponsePromise(responsePromise);
};


export const fetchAllDropdownMenus = async (): Promise<Either<string, DropdownMenu[]>> => {
    const responsePromise = apiClientAuth.get('/admin/dropdown/all');
    return handleResponsePromise(responsePromise);
};


export const setDropdownMenu = async (dropdown: DropdownMenu): Promise<Either<string, DropdownMenu[]>> => {
    const responsePromise = apiClientAuth.post("/admin/dropdown/set", dropdown);
    return handleResponsePromise(responsePromise);
};


export const deleteDropdownMenu = async (id: number): Promise<Either<string, DropdownMenu[]>> => {
    const responsePromise = apiClientAuth.post("/admin/dropdown/remove?id=" + id);
    return handleResponsePromise(responsePromise);
};


export const fetchSuggestionMenu = async (): Promise<Either<string, SuggestionCategory[]>> => {
    const responsePromise = apiClient.get('/suggestions/get');
    return handleResponsePromise(responsePromise);
};


export const setSuggestionMenu = async (category: SuggestionCategory): Promise<Either<string, SuggestionCategory[]>> => {
    const responsePromise = apiClientAuth.post("/admin/suggestions/set", category);
    return handleResponsePromise(responsePromise);
};


export const deleteSuggestionMenu = async (id: number): Promise<Either<string, SuggestionCategory[]>> => {
    const responsePromise = apiClientAuth.post("/admin/suggestions/remove?id=" + id);
    return handleResponsePromise(responsePromise);
};


export const fetchUserPage = async (page: number, size: number, search?: string, sortBy?: string, order?: string): Promise<Either<string, Page>> => {
    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    const responsePromise = apiClientAuth.get(`/admin/user/page?${params.toString()}`);
    return handleResponsePromise(responsePromise);
};


export const fetchOrdersByUser = async (userId: number): Promise<Either<string, PurchaseOrder[]>> => {
    const responsePromise = apiClientAuth.get("/admin/user-orders?userId=" + userId);
    return handleResponsePromise(responsePromise);
};


export const fetchConsignmentsByUser = async (userId: number): Promise<Either<string, ConsignmentDto[]>> => {
    const responsePromise = apiClientAuth.get("/admin/user-consignments?userId=" + userId);
    return handleResponsePromise(responsePromise);
};


export const fetchAdminPage = async (page: number, size: number, search?: string, sortBy?: string, order?: string): Promise<Either<string, Page>> => {
    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    const responsePromise = apiClientAuth.get(`/admin/user-admins/page?${params.toString()}`);
    return handleResponsePromise(responsePromise);
};


export const makeUserAdmin = async (userId: number): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.post("/admin/make-admin?userId=" + userId);
    return handleResponsePromise(responsePromise);
};


export const setUserRewards = async (userRewardsRequestDto: {userId: number, newRewards: number}): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.post("/admin/set-rewards", userRewardsRequestDto);
    return handleResponsePromise(responsePromise);
};


export const setUserBanned = async (banRequestDto: {userId: number, banned: boolean}): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.post("/admin/ban", banRequestDto);
    return handleResponsePromise(responsePromise);
};


export const removeUserAdmin = async (userId: number): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.post("/admin/remove-admin?userId=" + userId);
    return handleResponsePromise(responsePromise);
};


export const changePassword = async (adminPasswordChangeDto: { userId: number, newPassword: string }): Promise<Either<string, UserDto>> => {
    const responsePromise = apiClientAuth.post("/admin/change-password", adminPasswordChangeDto);
    return handleResponsePromise(responsePromise);
};


export const fetchOrdersPage = async (page: number, size: number, search?: string, orderStatus?: string, sortBy?: string, order?: string): Promise<Either<string, Page>> => {
    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(orderStatus && { orderStatus }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    const responsePromise = apiClientAuth.get(`/admin/orders/page?${params.toString()}`);
    return handleResponsePromise(responsePromise);
};


export const fetchOrderById = async (id: number): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.get(`/admin/orders/get?id=${id}`);
    return handleResponsePromise(responsePromise);
};


export const fetchOrderStatuses = async (): Promise<Either<string, string[]>> => {
    const responsePromise = apiClientAuth.get(`/orders/statuses`);
    return handleResponsePromise(responsePromise);
};


export const markETicketsStatus = async (OrderETicketsDeliveredDto: { id: number, files: string[] }): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/mark/e-tickets", OrderETicketsDeliveredDto);
    return handleResponsePromise(responsePromise);
};


export const markMobileDelivered = async (OrderMobileDeliveredDto: { id: number, links: string[] }): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/mark/mobile-delivered", OrderMobileDeliveredDto);
    return handleResponsePromise(responsePromise);
};


export const markComplete = async (OrderCompleteDto: { id: number }): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/mark/complete", OrderCompleteDto);
    return handleResponsePromise(responsePromise);
};


export const markShipped = async (orderShippedDto: { orderId: number, trackingNumber: string, trackingUrl: string }): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/mark/shipped", orderShippedDto);
    return handleResponsePromise(responsePromise);
};


export const markCancelled = async (orderId: number ): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/mark/cancelled?orderId=" + orderId);
    return handleResponsePromise(responsePromise);
};


export const processOrderPayment = async (orderId: number ): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/process-order?orderId=" + orderId);
    return handleResponsePromise(responsePromise);
};


export const releaseFunds = async (orderId: number ): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/release-funds?orderId=" + orderId);
    return handleResponsePromise(responsePromise);
};


export const markReadyForPickup = async (OrderReadyForPickupDto: { id: number }): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/mark/ready-for-pickup", OrderReadyForPickupDto);
    return handleResponsePromise(responsePromise);
};


export const issueRefund = async (orderRefundDto: { orderId: number, amount: number, refundDescription: string }): Promise<Either<string, ExpandedOrder>> => {
    const responsePromise = apiClientAuth.post("/admin/issue-refund", orderRefundDto);
    return handleResponsePromise(responsePromise);
};


export const submitConsignments = async (consignmentSubmissionDto: ConsignmentSubmission): Promise<Either<string, ConsignmentDto[]>> => {
    const responsePromise = apiClientAuth.post("/consignments/add", consignmentSubmissionDto);
    return handleResponsePromise(responsePromise);
}


export const fetchAccountConsignments = async (): Promise<Either<string, ConsignmentDto[]>> => {
    const responsePromise = apiClientAuth.get("/consignments/user/get");
    return handleResponsePromise(responsePromise);
}


export const fetchConsignmentsPage = async (page: number, size: number, search?: string, consignmentStatus?: string, sortBy?: string, order?: string): Promise<Either<string, Page>> => {
    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(consignmentStatus && { consignmentStatus }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    const responsePromise = apiClientAuth.get(`/admin/consignments/page?${params.toString()}`);
    return handleResponsePromise(responsePromise);
};


export const fetchConsignmentStatuses = async (): Promise<Either<string, string[]>> => {
    const responsePromise = apiClientAuth.get(`/admin/consignments/statuses`);
    return handleResponsePromise(responsePromise);
};


export const markConsignmentReturned = async (consignmentId: number): Promise<Either<string, ConsignmentDto>> => {
    const responsePromise = apiClientAuth.post("/admin/consignments/return?consignmentId=" + consignmentId);
    return handleResponsePromise(responsePromise);
};


export const markConsignmentUnsold = async (consignmentId: number): Promise<Either<string, ConsignmentDto>> => {
    const responsePromise = apiClientAuth.post("/admin/consignments/unsold?consignmentId=" + consignmentId);
    return handleResponsePromise(responsePromise);
};


export const markConsignmentCompleted = async (consignmentComplete: ConsignmentsComplete): Promise<Either<string, ConsignmentDto>> => {
    const responsePromise = apiClientAuth.post("/admin/consignments/complete", consignmentComplete);
    return handleResponsePromise(responsePromise);
};


export const fetchEventMarkups = async (): Promise<Either<string, MarkupEvent[]>> => {
    const responsePromise = apiClientAuth.get('/admin/all-event-markups');
    return handleResponsePromise(responsePromise);
};


export const fetchVenueMarkups = async (): Promise<Either<string, MarkupVenue[]>> => {
    const responsePromise = apiClientAuth.get('/admin/all-venue-markups');
    return handleResponsePromise(responsePromise);
};


export const fetchGeneralMarkups = async (): Promise<Either<string, MarkupGeneral[]>> => {
    const responsePromise = apiClientAuth.get('/admin/all-general-markups');
    return handleResponsePromise(responsePromise);
};


export const fetchMarkupTypes = async (): Promise<Either<string, string[]>> => {
    const responsePromise = apiClientAuth.get('/admin/markup-types');
    return handleResponsePromise(responsePromise);
};


export const fetchMarkupSources = async (): Promise<Either<string, string[]>> => {
    const responsePromise = apiClientAuth.get('/admin/markup-sources');
    return handleResponsePromise(responsePromise);
};


export const addGeneralMarkup = async (markupGeneral: MarkupGeneral): Promise<Either<string, MarkupGeneral>> => {
    const responsePromise = apiClientAuth.post("/admin/add-general-markup", markupGeneral);
    return handleResponsePromise(responsePromise);
}


export const addVenueMarkup = async (markupVenue: MarkupVenue): Promise<Either<string, MarkupVenue>> => {
    const responsePromise = apiClientAuth.post("/admin/add-venue-markup", markupVenue);
    return handleResponsePromise(responsePromise);
}


export const addEventMarkup = async (MarkupEvent: MarkupEvent): Promise<Either<string, MarkupEvent>> => {
    const responsePromise = apiClientAuth.post("/admin/add-event-markup", MarkupEvent);
    return handleResponsePromise(responsePromise);
}


export const removeMarkup = async (markupId: number): Promise<Either<string, boolean>> => {
    const responsePromise = apiClientAuth.post("/admin/remove-markup?id=" + markupId);
    return handleResponsePromise(responsePromise);
}


export const fetchGeneralBooleanSettings = async (): Promise<Either<string, GeneralSettingBoolean[]>> => {
    const responsePromise = apiClientAuth.get('/admin/general-rules/get-boolean');
    return handleResponsePromise(responsePromise);
};


export const fetchCouponAndRewardsBoolean = async (): Promise<Either<string, boolean>> => {
    const responsePromise = apiClientAuth.get('/admin/general-rules/coupon-rewards-boolean');
    return handleResponsePromise(responsePromise);
};


export const fetchGeneralNumberSettings = async (): Promise<Either<string, GeneralSettingNumber[]>> => {
    const responsePromise = apiClientAuth.get('/admin/general-rules/get-double');
    return handleResponsePromise(responsePromise);
};


export const setGeneralBooleanSetting = async (settingBooleanDto: GeneralSettingBoolean): Promise<Either<string, GeneralSettingBoolean>> => {
    const responsePromise = apiClientAuth.post('/admin/general-rules/set-boolean', settingBooleanDto);
    return handleResponsePromise(responsePromise);
}


export const setGeneralNumberSetting = async (settingDoubleDto: GeneralSettingNumber): Promise<Either<string, GeneralSettingNumber>> => {
    const responsePromise = apiClientAuth.post('/admin/general-rules/set-double', settingDoubleDto);
    return handleResponsePromise(responsePromise);
}


export const getEnabledShipping = async (): Promise<Either<string, ShippingPricing[]>> => {
    const responsePromise = apiClient.get(`/shipping/all-enabled`);
    return handleResponsePromise(responsePromise);
}


export const fetchCouponDiscountTypes = async (): Promise<Either<string, string[]>> => {
    const responsePromise = apiClientAuth.get(`/admin/coupon/discount-types`);
    return handleResponsePromise(responsePromise);
};


export const fetchCouponCategoryTypes = async (): Promise<Either<string, string[]>> => {
    const responsePromise = apiClientAuth.get(`/admin/coupon/category-types`);
    return handleResponsePromise(responsePromise);
};


export const getAllShipping = async (): Promise<Either<string, ShippingPricing[]>> => {
    const responsePromise = apiClientAuth.get(`/admin/shipping/all`);
    return handleResponsePromise(responsePromise);
}


export const addShippingMethod = async (shippingPricing: ShippingPricing): Promise<Either<string, ShippingPricing>> => {
    const responsePromise = apiClientAuth.post("/admin/shipping/add", shippingPricing);
    return handleResponsePromise(responsePromise);
}


export const fetchAllCoupons = async (): Promise<Either<string, CouponDto[]>> => {
    const responsePromise = apiClientAuth.get('/admin/coupon/all');
    return handleResponsePromise(responsePromise);
};


export const removeShippingMethod = async (id: number): Promise<Either<string, boolean>> => {
    const responsePromise = apiClientAuth.post("/admin/shipping/remove?id=" + id);
    return handleResponsePromise(responsePromise);
}


export const makeCoupon = async (coupon: CouponDto): Promise<Either<string, CouponDto>> => {
    const responsePromise = apiClientAuth.post("/admin/coupon/add", coupon);
    return handleResponsePromise(responsePromise);
};


export const deleteCoupon = async (couponId: number): Promise<Either<string, string>> => {
    const responsePromise = apiClientAuth.post("/admin/coupon/remove?couponId=" + couponId);
    return handleResponsePromise(responsePromise);
};


export const modifyCoupon = async (couponId: number, couponDto: CouponDto): Promise<Either<string, CouponDto>> => {
    const responsePromise = apiClientAuth.post("/admin/coupon/modify?couponId=" + couponId, couponDto);
    return handleResponsePromise(responsePromise);
};


export const fetchCouponStats = async (couponId: number): Promise<Either<string, CouponStatsDto>> => {
    const responsePromise = apiClientAuth.get("/admin/coupon/stats?couponId=" + couponId);
    return handleResponsePromise(responsePromise);
};


export const fetchAllCustomEvents = async (): Promise<Either<string, CustomEventDto[]>> => {
    const responsePromise = apiClientAuth.get('/admin/custom-events/all');
    return handleResponsePromise(responsePromise);
};


export const makeCustomEvent = async (customEvent: CustomEventDto): Promise<Either<string, CustomEventDto>> => {
    const responsePromise = apiClientAuth.post("/admin/custom-events/add", customEvent);
    return handleResponsePromise(responsePromise);
};


export const removeCustomEvent = async (id: number): Promise<Either<string, boolean>> => {
    const responsePromise = apiClientAuth.post("/admin/custom-events/remove?id=" + id);
    return handleResponsePromise(responsePromise);
}


export const addCustomListing = async (addCustomListingDto: { listing: Listing, eventId: number }): Promise<Either<string, Listing[]>> => {
    const responsePromise = apiClientAuth.post("/admin/custom-events/add-listing", addCustomListingDto);
    return handleResponsePromise(responsePromise);
};


export const addListingCSV = async (listingsCsv: File, eventId: number): Promise<Either<string, Listing[]>> => {
    const formData = new FormData();
    formData.append('listingsCsv', listingsCsv);
    formData.append('eventId', eventId.toString());
    const responsePromise = apiClientAuth.post("/admin/custom-events/add-listing-csv", formData);
    return handleResponsePromise(responsePromise);
};


export const removeCustomListing = async (removeCustomListingDto: { listingId: number, eventId: number }): Promise<Either<string, Listing[]>> => {
    const responsePromise = apiClientAuth.post("/admin/custom-events/remove-listing", removeCustomListingDto);
    return handleResponsePromise(responsePromise);
};


export const fetchAllCustomListings = async (id: number): Promise<Either<string, Listing[]>> => {
    const responsePromise = apiClientAuth.get("/admin/custom-events/listings?eventId=" + id);
    return handleResponsePromise(responsePromise);
};


