import React, {useEffect, useState} from 'react';
import EventListings from './EventListings';
import EventMap from './EventMap';
import Listing from '../../interfaces/Listing';
import Event from '../../interfaces/Event';
import ListingType from '../../interfaces/ListingType';
import ListingSource from '../../interfaces/ListingSource';
import EventMapImage from './EventMapImage';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';


export const quantityMap = new Map([["1 Ticket", 1], ["2 Tickets", 2], ["3 Tickets", 3], ["4 Tickets", 4], ["5 Tickets", 5], ["6 Tickets", 6], ["7 Tickets", 7], ["8+ Tickets", 8]])

interface EventViewProps {
    event: Event | null;
    loadingEvent: boolean
    listings: Listing[]
    loadingListings: boolean
}

const EventView: React.FC<EventViewProps> = ({ event, loadingEvent, listings, loadingListings }) => {
    const [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);
    const [selectedQuantity, setSelectedQuantity] = useState('');
    const [selectedPrices, setSelectedPrices] = useState<[number, number]>([0, 0]);
    const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
    const [parkingFiltered, setParkingFiltered] = useState(false);
    const [mapSections, setMapSections] = useState<Set<string>>(new Set());
    const [filteredListings, setFilteredListings] = useState<Listing[]>([]);
    const [filteredSectionsSet, setFilteredSectionsSet] = useState<Set<string>>(new Set());
    const [selectedSections, setSelectedSections] = useState<Set<string>>(new Set());

    const { isMapMobile } = useScreenSizeContext();

    const quantityOptions = ["1 Ticket", "2 Tickets", "3 Tickets", "4 Tickets", "5 Tickets", "6 Tickets", "7 Tickets", "8+ Tickets"]


    useEffect(() => {
        const quantity = quantityMap.get(selectedQuantity);
        const onlyFilters = listings.filter(listing =>
            (selectedQuantity === '' || (quantity !== undefined && listing.splits.includes(quantity))) &&
            (listing.price >= selectedPrices[0] && listing.price <= selectedPrices[1]) &&
            (parkingFiltered ? listing.type == ListingType.PARKING : listing.type == ListingType.EVENT) &&
            (event?.listingSource == ListingSource.CUSTOM || mapSections.size == 0 || (parkingFiltered || mapSections.has(listing.tevoSectionName)))
        );

        setFilteredSectionsSet(new Set(onlyFilters.map(listing => listing.tevoSectionName)))
        setFilteredListings(onlyFilters)

    }, [listings, mapSections, selectedQuantity, selectedPrices, parkingFiltered])

    useEffect(() => {
        setSelectedListing(null);
    }, [selectedSections])


    useEffect(() => {
        if (!loadingListings) {
            const eventOrParkingListings = listings.filter(listing => (parkingFiltered ? listing.type == ListingType.PARKING : listing.type == ListingType.EVENT));
            const minPrice = Math.min(...eventOrParkingListings.map(listing => listing.price));
            const maxPrice = Math.max(...eventOrParkingListings.map(listing => listing.price));
            const range: [number, number] = [Math.floor(minPrice), Math.ceil(maxPrice)]
            setPriceRange(range);
            setSelectedPrices(range);
        }

    }, [loadingListings, parkingFiltered])

    return (
        <div className='container-view'>

            {isMapMobile && (
                loadingEvent || event == null ?
                    <div className='loading-spinner' />
                    :
                    event.listingSource == ListingSource.CUSTOM ?
                        <EventMapImage mapImageLocation={event.configuration.name} />
                        :
                        <EventMap
                            event={event}
                            sections={filteredSectionsSet}
                            selectedListing={selectedListing}
                            setParentSelectedSections={setSelectedSections}
                            setMapSections={setMapSections}
                        />

            )}
            <div style={{zIndex: "999"}}>
                <EventListings
                    loadingEvent={loadingEvent}
                    loadingListings={loadingListings}
                    event={event}
                    listings={filteredListings.filter(listing => (selectedSections.size == 0 || selectedSections.has(listing.tevoSectionName)))}
                    priceRange={priceRange}
                    quantityOptions={quantityOptions}
                    selectedListing={selectedListing}
                    setSelectedListing={setSelectedListing}
                    selectedQuantity={selectedQuantity}
                    setSelectedQuantity={setSelectedQuantity}
                    selectedPrices={selectedPrices}
                    setSelectedPrices={setSelectedPrices}
                    parkingFiltered={parkingFiltered}
                    setParkingFiltered={setParkingFiltered}
                />
            </div>
            {!isMapMobile && (
                loadingEvent || event == null ?
                    <div className='loading-spinner' />
                    :
                    event.listingSource == ListingSource.CUSTOM ?
                        <EventMapImage mapImageLocation={event.configuration.name} />
                        :
                        <EventMap
                            event={event}
                            sections={filteredSectionsSet}
                            selectedListing={selectedListing}
                            setParentSelectedSections={setSelectedSections}
                            setMapSections={setMapSections}
                        />
            )}
        </div>
    )
}

export default EventView;
