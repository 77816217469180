import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {isRight} from 'fp-ts/lib/Either';
import {triggerUserVerification} from "../../api/endpoints"
import logo from "../../assets/714-logo-hover.svg";
import {useScreenSizeContext} from "../../providers/ScreenSizeProvider";


const VerificationCard: React.FC = () => {
    const { verificationToken } = useParams<{ verificationToken: string }>();
    const {isMobile} = useScreenSizeContext();

    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(false);


    useEffect(() => {
        const verifyUser = async () => {
            setLoading(true)
            if (verificationToken !== undefined) {
                const verificationResponse = await triggerUserVerification(verificationToken)
                if (isRight(verificationResponse)) {
                    setIsVerified(true);
                } else {
                    console.error(verificationResponse.left)
                    setIsVerified(false);
                }
            }
            setLoading(false)
        }
        verifyUser();
    }, [verificationToken]);


    return (
        <div className='card'>
            <div className='row'>
                <div className='left'>
                    {loading ?
                        <div className='text-l'>Verifying...</div>
                        :
                        <div className='text-l'>{isVerified ? "Welcome to 714 Tickets!" : "Issue Verifying"}</div>
                    }
                </div>
                {!isMobile && <img src={logo} alt="714 Logo" style={{width: '140px', height: 'auto', marginBottom: "4px"}}/>}
            </div>
            <div className='card-item'>
                {loading ? <div className="loading-spinner"></div> :
                    <div
                        className='text-s-light'>{isVerified ? "Your email address has been verified." : "Failed to verify your email address. Please try again"}</div>
                }
            </div>
        </div>
    )
}

export default VerificationCard;
