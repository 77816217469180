import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RiSearchLine} from 'react-icons/ri';
import Performer from '../../interfaces/Performer';
import {fetchEventsByPerformer, fetchPerformersByQuery, submitConsignments} from '../../api/endpoints';
import {useLocation, useNavigate} from "react-router-dom";
import Address from '../../interfaces/Address';
import Event from '../../interfaces/Event';
import ConsignmentSeatDetails from '../../interfaces/ConsignmentSeatDetails';
import ConsignmentSingleSubmission from '../../interfaces/ConsignmentSingleSubmission';
import ConsignmentSubmission from '../../interfaces/ConsignmentSubmission';
import {formatTimeZonedDateToFullDate} from '../../utils/DateUtils/DateUtils';
import ConsignmentResponseCard from './ConsignmentResponseCard';
import {useAuthenticationContext} from '../../providers/AuthenticationProvider';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';
import {FaTimes} from 'react-icons/fa';
import logo from '../../assets/714-logo-hover.svg';
import {isLeft, isRight} from 'fp-ts/lib/Either';


interface ConsignmentFormCardProps {
    event: Event | null;
}

const ConsignmentFormCard: React.FC<ConsignmentFormCardProps> = ({ event }) => {

    const {user } = useAuthenticationContext();

    const [performerQuery, setPerformerQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [performerResults, setPerformerResults] = useState<Performer[] | null>(null);
    const [selectedPerformer, setSelectedPerformer] = useState<Performer | null>(null);
    const [events, setEvents] = useState<Event[] | null>(null);
    const [selectedEvents, setSelectedEvents] = useState<Event[]>(event == null ? [] : [event]);
    const [selectedTransfer, setSelectedTransfer] = useState('Mobile transfer');
    const [loadingPerformers, setLoadingPerformers] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [loadingSubmission, setLoadingSubmission] = useState(false);
    const [consignmentResponse, setConsignmentResponse] = useState<any>(null);
    const [submittedConsignments, setSubmittedConsignments] = useState<number[]>([])
    const [inputErrors, setInputErrors] = useState({
        events: false,
        confirm: false,
    })
    const [error, setError] = useState(false);
    const [transferred, setTransferred] = useState(false)

    const [quantities, setQuantities] = useState(['']);
    const [sections, setSections] = useState(['']);
    const [rows, setRows] = useState(['']);
    const lastSearchedRef = useRef('');

    const [mobilePhone, setMobilePhone] = useState(user ? user.billPhoneNumber : '');
    const [firstName, setFirstName] = useState(user ? user.firstName : '');
    const [lastName, setLastName] = useState(user ? user.lastName : '');
    const [addressLine1, setAddressLine1] = useState(user ? user.billAddress : '');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState(user ? user.billCity : '');
    const [country, setCountry] = useState(user ? user.billCountry : '');
    const [zipCode, setZipCode] = useState(user ? user.billZip : '');
    const [state, setState] = useState(user ? user.billState : '');


    const [saveBillingInfo, setSaveBillingInfo] = useState(true);

    const searchRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useScreenSizeContext();

    const formRef = useRef<HTMLFormElement | null>(null);

    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        const handleClickOutsideSearch = (e: MouseEvent) => {
            if (searchRef.current && e.target instanceof Node && !searchRef.current.contains(e.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideSearch);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSearch);
        };
    }, []);


    const handlePerformerQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPerformerQuery(e.target.value);
    };

    const handleSectionChange = (groupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSections = [...sections];
        updatedSections[groupIndex] = e.target.value;
        setSections(updatedSections);
    };

    const handleRowChange = (groupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedRows = [...rows];
        updatedRows[groupIndex] = e.target.value;
        setRows(updatedRows);
    };

    const handleQuantityChange = (groupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedQuantities = [...quantities];
        updatedQuantities[groupIndex] = e.target.value;
        setQuantities(updatedQuantities);
    };

    const addTicketGroup = () => {
        setQuantities([...quantities, '']);
        setSections([...sections, '']);
        setRows([...rows, '']);
    }

    const removeTicketGroup = (groupIndex: number) => {
        setQuantities(quantities.filter((_, index) => index !== groupIndex));
        setSections(sections.filter((_, index) => index !== groupIndex));
        setRows(rows.filter((_, index) => index !== groupIndex));
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleMobilePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMobilePhone(e.target.value);
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const handleTransferredChange = (value: boolean) => {
        setTransferred(value);
        const newInputErrors = {
            ...inputErrors,
            confirm: false,
        };
        setInputErrors(newInputErrors);
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    const handleAddressLine1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddressLine1(e.target.value);
    };

    const handleAddressLine2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddressLine2(e.target.value);
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value);
    };

    const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCountry(e.target.value);
    };

    const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setState(e.target.value);
    };

    const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setZipCode(e.target.value);
    };


    const fetchPerformerResults = async (query: string) => {
        if (query === '') {
            setPerformerResults(null);
            return;
        }
        setLoadingPerformers(true);
        lastSearchedRef.current = query;

        const resultResponseEither = await fetchPerformersByQuery(query, false);
        if (isRight(resultResponseEither)) {
            setPerformerResults(resultResponseEither.right);
        } else {
            console.error(resultResponseEither.left)
        }
        setLoadingPerformers(false);
    }

    const debounceFetchResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchPerformerResults(searchQuery);
                }, 300);
            };
        })(),
        []
    );

    const handleSelectedEvent = (event: Event) => {
        setSelectedEvents(prevSelectedEvents => {
            if (prevSelectedEvents.includes(event)) {
                return prevSelectedEvents.filter(i => i !== event);
            } else {
                return [...prevSelectedEvents, event];
            }
        });

        const newInputErrors = {
            ...inputErrors,
            events: false,
        };
        setInputErrors(newInputErrors);
    };

    const handleSelectedPerformer = (performer: Performer) => {
        setSelectedPerformer(performer)
        setIsFocused(false);
        setSelectedEvents([]);
    }

    useEffect(() => {
        debounceFetchResults(performerQuery);
    }, [performerQuery, debounceFetchResults]);

    useEffect(() => {
        const fetchEvents = async () => {
            setLoadingEvents(true);
            if (selectedPerformer !== null) {
                const eventsDataEither = await fetchEventsByPerformer(selectedPerformer.tevoPerformerId.toString());
                if (isRight(eventsDataEither)) {
                    setEvents(eventsDataEither.right);
                } else {
                    console.error(eventsDataEither.left)
                }
            }
            setLoadingEvents(false);
        };

        fetchEvents();
    }, [selectedPerformer]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        const newInputErrors = {
            events: selectedEvents.length == 0,
            confirm: !transferred
        }


        setInputErrors(newInputErrors)

        if (formRef.current && formRef.current.checkValidity() && !(Object.values(newInputErrors).includes(true))) {

            setLoadingSubmission(true)
            const submittedIds = [];
            for (let i = 0; i < quantities.length; i++) {
                const consignmentSeatDetails: ConsignmentSeatDetails = { section: sections[i], row: rows[i], quantity: Number(quantities[i]) };
                const billingInfo: Address = { addressOne: addressLine1, city: city, state: state, country: country, zipCode: zipCode, phoneNumber: mobilePhone };
                const singleConsignments: ConsignmentSingleSubmission[] = selectedEvents.map(event => ({ event: event, billingInfo: billingInfo, seatDetails: consignmentSeatDetails, deliveryMethod: selectedTransfer }))
                const consignmentSubmission: ConsignmentSubmission = { consignments: singleConsignments, saveBillingInfo: saveBillingInfo }
                const consignmentAddEither = await submitConsignments(consignmentSubmission);
                if (isLeft(consignmentAddEither)) {
                    console.error(consignmentAddEither.left);
                    setConsignmentResponse(false);
                    setLoadingSubmission(false)
                    return;
                }

                const actualAddResponse = consignmentAddEither.right;
                submittedIds.push(...actualAddResponse.map(consignment => consignment.id));
            }
            setSubmittedConsignments(submittedIds)
            setConsignmentResponse(true);
            navigate(location.pathname + '/success',  { replace: true })
            setLoadingSubmission(false)
        } else {
            formRef.current?.reportValidity();
            setError(true)
        }
    }


    return (
        loadingSubmission ?
            <div className="loading-spinner"></div>
            :
            consignmentResponse == null ?


                <form className={isMobile ? '' : 'card'} onSubmit={handleSubmit} ref={formRef} noValidate>
                    <div className='card-item'>
                        <div className='row'>
                            <div className='left'>
                                <div className='text-l'>Consignment Program</div>
                            </div>
                            {!isMobile && <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />}
                        </div>
                    </div>

                    <div className='card-item'>
                        <div className='text-s'>Find Your Event, Venue, and Date</div>
                    </div>
                    <div ref={searchRef}>
                        <div className='card-item'>
                            <div className={`row search-form-consignment ${inputErrors.events ? 'input-error' : ''}`}>
                            {!loadingPerformers ?
                                <RiSearchLine className='search-icon focused-icon'/>
                                :
                                <div className={`search-loading-spinner ${isFocused ? 'focused-search-loading-spinner' : ''}`}></div>
                            }
                            <input
                                type="text"
                                value={performerQuery}
                                onFocus={handleFocus}
                                onChange={handlePerformerQueryChange}
                                placeholder={isMobile ? "Performer or team name..." : "Search for the name of the performer or team"}
                                className='search-input-consignment'
                            />
                            </div>
                        </div>
                        {(performerResults !== null && isFocused) &&
                            <div className='relative'>
                                <div className='input-search-results'>
                                    {performerResults.map(performer => (
                                        <div className="menu-item" onClick={() => handleSelectedPerformer(performer)}>
                                            <div className='menu-item-title'>{performer.name}</div>
                                            <div className='menu-item-subtitle'>{performer.category.name}</div>
                                        </div>))}
                                </div>
                            </div>
                        }
                    </div>
                    {inputErrors.events &&

                                <div className='card-list'>
                                    <div className={`text-xs-error`}>
                                        Please select at least one event
                                    </div>
                                </div>

                    }
                    {loadingEvents && <div className='loading-spinner'/> }
                    {(selectedPerformer !== null && events !== null) &&
                        <>
                            <div className='card-item'>
                                <div className='text-s'>{selectedPerformer.name + " Events (Click to Select)"}</div>
                            </div>
                            <div className='card-item'>
                                <div className='card-list max-height-m'>
                                    {selectedPerformer.venue == null ?

                                        events.map(event => (
                                            <div className={`menu-item ${selectedEvents.map(event => event.tevoEventId).includes(event.tevoEventId) && "menu-item-selected"}`} onClick={() => handleSelectedEvent(event)}>
                                                <div className='text-xs-bold'>{event.name}</div>
                                                <div className='text-xs-light'> {event.venue.name}  - {formatTimeZonedDateToFullDate(event.occursAtLocal)}</div>
                                            </div>
                                        ))
                                        :
                                        <>
                                            <div className='text-s margin-v'>&nbsp;&nbsp;Home</div>
                                            {events.filter(event => event.venue.tevoVenueId === selectedPerformer.venue?.tevoVenueId).map(homeEvent => (
                                                <div className={`menu-item ${selectedEvents.map(event => event.tevoEventId).includes(homeEvent.tevoEventId) && "menu-item-selected"}`} onClick={() => handleSelectedEvent(homeEvent)}>
                                                    <div className='text-xs-bold'>{homeEvent.name}</div>
                                                    <div className='text-xs-light'> {homeEvent.venue.name}  - {formatTimeZonedDateToFullDate(homeEvent.occursAtLocal)}</div>
                                                </div>
                                            ))}
                                            <div className='divider-margin'></div>
                                            <div className='text-s margin-v'>&nbsp;&nbsp;Away</div>
                                            {events.filter(event => event.venue.tevoVenueId !== selectedPerformer.venue?.tevoVenueId).map(awayEvent => (
                                                <div className={`menu-item ${selectedEvents.map(event => event.tevoEventId).includes(awayEvent.tevoEventId) && "menu-item-selected"}`} onClick={() => handleSelectedEvent(awayEvent)}>
                                                    <div className='text-xs-bold'>{awayEvent.name}</div>
                                                    <div className='text-xs-light'> {awayEvent.venue.name}  - {formatTimeZonedDateToFullDate(awayEvent.occursAtLocal)}</div>
                                                </div>
                                            ))}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='card-item'>
                                <div className='row'>
                                    <button className='action-button' type="button" style={{ fontSize: "18px", width: "100%" }} onClick={() => setSelectedEvents(events)}>{isMobile ? "All Events" : "Select All Events"}</button>
                                    {selectedPerformer.venue != null &&
                                        <>
                                            <div className='gap-s' />
                                            <button className='action-button' type="button" style={{ fontSize: "18px", width: "100%" }} onClick={() => setSelectedEvents(events.filter(event => event.venue.tevoVenueId === selectedPerformer.venue?.tevoVenueId))}>{isMobile ? "All Home Events" : "Select All Home Events"}</button>
                                        </>

                                    }

                                </div>
                            </div>
                        </>
                    }

                    {selectedEvents.length > 0 &&
                        <>
                            <div className='card-item'>
                                <div className='text-s'>Selected Events (Click to Remove)</div>
                            </div>
                            <div className='card-item'>
                                <div className='card-list max-height-m'>
                                    {selectedEvents.map(awayEvent => (
                                        <div className="menu-item" onClick={() => handleSelectedEvent(awayEvent)}>
                                            <div className='text-xs-bold'>{awayEvent.name}</div>
                                            <div className='text-xs-light'> {awayEvent.venue.name}  - {formatTimeZonedDateToFullDate(awayEvent.occursAtLocal)}</div>
                                        </div>))
                                    }
                                </div>
                            </div>
                            <div className='card-item'>
                                <button className='action-button' type="button" style={{ fontSize: "18px", width: "100%" }} onClick={() => setSelectedEvents([])}>Remove All Events</button>
                            </div>
                        </>
                    }
                    {selectedEvents.length > 0 &&

                        <>
                            <div className='card-item'>
                                <div className='text-s'>Add Your Ticket Details</div>
                            </div>
                            {quantities.map((_, index) => (
                                <div className='card-item'>
                                    <div className='row'>
                                        <input
                                            type="number"
                                            value={quantities[index]}
                                            onChange={handleQuantityChange(index)}
                                            placeholder="Quantity"
                                            className='input'
                                            required
                                        />
                                        <div className='gap-s' />
                                        <input
                                            type="text"
                                            value={sections[index]}
                                            onChange={handleSectionChange(index)}
                                            placeholder="Section"
                                            className='input'
                                            required
                                        />
                                        <div className='gap-s' />
                                        <input
                                            type="text"
                                            value={rows[index]}
                                            onChange={handleRowChange(index)}
                                            placeholder="Row"
                                            className='input'
                                            required
                                        />
                                        {index > 0 ?
                                            <>
                                                <div className='gap-s' />
                                                <button className='filter-button' type="button" style={{ border: "0.5px solid gray", borderRadius: "5px", height: "45px" }} onClick={() => removeTicketGroup(index)}>
                                                    <div className='col'>
                                                        <FaTimes />
                                                    </div>

                                                </button>
                                            </>
                                            :
                                            <>
                                                {quantities.length > 1 && <div style={{ minWidth: "72px" }} />}

                                            </>

                                        }
                                    </div>
                                </div>
                            ))}
                            {quantities.length > 1 &&

                                <div className='card-item'>
                                    <div className='text-xs-light'>* Submitting multiple ticket groups applies to ALL selected events</div>
                                </div>
                            }

                            <div className='card-item'>
                                <button className='action-button' type="button" style={{ fontSize: "18px" }} onClick={addTicketGroup}>Add Another Ticket Group</button>
                            </div>

                        </>
                    }
                    <div className='card-item'>
                        <div className='text-s'>Choose How to Transfer Your Tickets</div>
                    </div>
                    <div className='card-item'>
                        <div className={`${isMobile ? "col" : "row space-between"} `}>
                            <div className='row'>
                                <input
                                    type="checkbox"
                                    checked={selectedTransfer == "Mobile transfer"}
                                    onChange={() => setSelectedTransfer("Mobile transfer")}
                                    className='checkbox'
                                />
                                <div className='text-xs-light'>Mobile Transfer</div>
                            </div>
                            <div className='row'>
                                <input
                                    type="checkbox"
                                    checked={selectedTransfer == "Physical tickets"}
                                    onChange={() => setSelectedTransfer("Physical tickets")}
                                    className='checkbox'
                                />
                                <div className='text-xs-light'>Physical Tickets</div>
                            </div>

                            <div className='row'>
                                <input
                                    type="checkbox"
                                    checked={selectedTransfer == "PDF tickets"}
                                    onChange={() => setSelectedTransfer("PDF tickets")}
                                    className='checkbox'
                                />
                                <div className='text-xs-light'>PDF Tickets</div>
                            </div>
                        </div>

                    </div>
                    <div className='card-item'>
                        <div className='card-list'>
                            <div className={`text-xs-important`}>
                                {selectedTransfer === 'Physical tickets' ? 'Drop off or ship your tickets to 2424 E Katella Ave, Anaheim, CA 92806.' :
                                    selectedTransfer === 'Mobile transfer' ? 'Transfer your tickets to consignments714@gmail.com.'
                                        : selectedTransfer === 'PDF tickets' ? 'Email 714Tickets at consignments714@gmail.com with your file attachment.' : ''}
                            </div>
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="checkbox"
                                checked={transferred}
                                onChange={() => handleTransferredChange(!transferred)}
                                className='checkbox'
                                required
                            />
                            <div className={`${inputErrors.confirm ? 'text-xs-error' : 'text-xs'}`}>I confirm that I have transferred my tickets to 714Tickets.</div>
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='text-s'>Provide Your Billing Info</div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="text"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                placeholder="First Name"
                                className='input'
                                required
                            />
                            <div className='gap-m' />
                            <input
                                type="text"
                                value={lastName}
                                onChange={handleLastNameChange}
                                placeholder="Last Name"
                                className='input'
                            />
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="text"
                                value={addressLine1}
                                onChange={handleAddressLine1Change}
                                placeholder="Address Line 1"
                                className='input'
                                required
                            />
                            <div className='gap-m' />
                            <input
                                type="text"
                                value={addressLine2}
                                onChange={handleAddressLine2Change}
                                placeholder="Address Line 2"
                                className='input'
                            />
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="text"
                                value={city}
                                onChange={handleCityChange}
                                placeholder="City"
                                className='input'
                                required
                            />

                            <div className='gap-m' />

                            <input
                                type="number"
                                value={zipCode}
                                onChange={handleZipCodeChange}
                                placeholder="Zip Code"
                                className='input'
                                required
                            />
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <select
                                value={country}
                                onChange={handleCountryChange}
                                className='input'
                                required
                            >
                                <option value="" disabled>Select Country</option>
                                <option value="US">United States</option>
                                <option value="CA">Canada</option>
                            </select>
                            <div className='gap-m' />
                            <select
                                value={state}
                                onChange={handleStateChange}
                                className='input'
                                required
                            >
                                <option value="" disabled>Select State/Province</option>
                                {country === "US" && (
                                    <>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </>
                                )}
                                {country === "CA" && (
                                    <>
                                        <option value="AB">Alberta</option>
                                        <option value="BC">British Columbia</option>
                                        <option value="MB">Manitoba</option>
                                        <option value="NB">New Brunswick</option>
                                        <option value="NL">Newfoundland and Labrador</option>
                                        <option value="NT">Northwest Territories</option>
                                        <option value="NS">Nova Scotia</option>
                                        <option value="NU">Nunavut</option>
                                        <option value="ON">Ontario</option>
                                        <option value="PE">Prince Edward Island</option>
                                        <option value="QC">Quebec</option>
                                        <option value="SK">Saskatchewan</option>
                                        <option value="YT">Yukon</option>
                                    </>
                                )}
                            </select>

                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="tel"
                                value={mobilePhone}
                                onChange={handleMobilePhoneChange}
                                placeholder="Mobile Phone"
                                className='input'
                                required
                            />
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="checkbox"
                                checked={saveBillingInfo}
                                onChange={() => setSaveBillingInfo(!saveBillingInfo)}
                                className='checkbox'
                            />
                            <div className='text-xs'>Save info to my account for future orders and consignments</div>
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='text-xs-light'>By clicking the “Submit” button below, you are agreeing to our Terms and Conditions of the Consignment Program as stated below.</div>
                    </div>
                    <div className='card-item'>
                        <button className='action-button-full' type="submit" onClick={() => (window.scrollTo(0, 0))}>Submit</button>
                    </div>
                    {error &&
                        <>
                            <div className='card-list'>
                                <div className={`text-xs-error`}>
                                    Please make sure you have selected events and provided all required information
                                </div>
                            </div>
                        </>
                    }
                </form>
                :
                <ConsignmentResponseCard success={consignmentResponse} submittedConsignments={submittedConsignments} />
    )
}

export default ConsignmentFormCard;
