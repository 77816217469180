import React, {createContext, useContext, useEffect, useState} from 'react';
import {BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import {fetchAdminWriteEticketsSASToken, fetchAdminWriteImagesSASToken} from "../api/endpoints";
import {Either, isRight} from "fp-ts/lib/Either";


class WriteBlobService {

    private containerClient: ContainerClient;

    private constructor(containerClient: ContainerClient) {
        this.containerClient = containerClient;
    }

    static async create(containerName: string, imagesOrEtickets: boolean): Promise<WriteBlobService | null> {
        let sasResponseEither: Either<string, string>;
        if (imagesOrEtickets) {
            sasResponseEither = await fetchAdminWriteImagesSASToken()
        } else {
            sasResponseEither = await fetchAdminWriteEticketsSASToken()
        }
        if (!isRight(sasResponseEither)) {
            console.error(sasResponseEither.left)
            return null
        }
        const containerSAS = sasResponseEither.right;
        const blobUrl = process.env.REACT_APP_BLOB_URL;
        const blobServiceClient = new BlobServiceClient(blobUrl + "?" + containerSAS);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const instance = new WriteBlobService(containerClient);
        return instance;
    }


    async uploadFile(filePath: string, file: File) {
        const blockBlobClient = this.containerClient.getBlockBlobClient(filePath);
        await blockBlobClient.uploadData(file);
    }
}


interface WriteBlobContextType {
    imageWriteBlobService: WriteBlobService | null;
    eticketWriteBlobService: WriteBlobService | null;
}

const WriteBlobContext = createContext<WriteBlobContextType>({
    imageWriteBlobService: null,
    eticketWriteBlobService: null
});

export const useWriteBlobContext = () => useContext(WriteBlobContext);

interface WriteBlobProviderProps {
    children: React.ReactNode;
}


// Create a provider
export const WriteBlobProvider: React.FC<WriteBlobProviderProps> = ({ children }) => {
    const [imageWriteBlobService, setImageWriteBlobService] = useState<WriteBlobService | null>(null);
    const [eticketWriteBlobService, setEticketWriteBlobService] = useState<WriteBlobService | null>(null);

    const imagesContainer = process.env.REACT_APP_IMAGES_CONTAINER as string;
    const eticketsContainer = process.env.REACT_APP_ETICKETS_CONTAINER as string;

    useEffect(() => {
        const initialize = async () => {
            const imageService = await WriteBlobService.create(imagesContainer, true);
            setImageWriteBlobService(imageService);

            const eticketService = await WriteBlobService.create(eticketsContainer, false);
            setEticketWriteBlobService(eticketService);
        }

        initialize();
    }, []);


    return (
        <WriteBlobContext.Provider value={{imageWriteBlobService, eticketWriteBlobService}}>
            {children}
        </WriteBlobContext.Provider>
    );
};

export default WriteBlobProvider;
