import React, {useEffect, useState} from 'react';
import './Heros.css';
import Venue from '../../interfaces/Venue';
import {useReadBlobContext} from '../../providers/ReadBlobProvider';

interface VenueHeroProps {
    venue: Venue | null;
    loadingVenue: boolean;
}

const VenueHero: React.FC<VenueHeroProps> = ({venue, loadingVenue}) => {

    const {imageReadBlobService} = useReadBlobContext();
    const [imageUrl, setImageUrl] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (imageReadBlobService !== null && venue !== null) {
            const slugImageUrl = imageReadBlobService.getUrlIfPathExists("venues/specific/" + venue.name + "/img.jpg")
            if (slugImageUrl) {
                setImageUrl(slugImageUrl)
            } else {
                const categoryImageUrl = imageReadBlobService.getImageUrlForCategoryPath('performers');
                setImageUrl(categoryImageUrl)
            }
        }

    }, [imageReadBlobService, venue]);

      useEffect(() => {
        if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => setIsLoaded(true);
        }
      }, [imageUrl]);


    return (
        <div className= "hero-container"       style={{ backgroundImage: `url(${imageUrl})` }} >
        {(isLoaded && !loadingVenue && venue !== null) ?
                <>
                    <div className='hero-title'>
                        {venue.name.toUpperCase()}
                    </div>
                    <div style={{height: "5px"}}></div>
                    <div className='hero-subtitle'>{venue.location}</div>
                </>
                :
                <div className="loading-spinner"></div>
            }
        </div>
    )
}

export default VenueHero;
