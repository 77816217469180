import React, {useEffect, useRef, useState} from 'react';
import {DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel} from '@mui/x-data-grid';
import {fetchOrderById, fetchOrdersPage, fetchOrderStatuses} from '../../api/endpoints';
import ExpandedOrder from '../../interfaces/ExpandedOrder';
import {SingleSelectMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import {isRight} from 'fp-ts/lib/Either';
import Modal from 'react-modal';
import AdminOrderModal from './AdminOrderModal';
import {formatDateToSlash} from '../../utils/DateUtils/DateUtils';


interface RowData {
    id: number;
    userFirstName: string,
    userLastName: string,
    eventName: string;
    eventVenue: string;
    eventDate: string;
    purchaseDate: string;
    section: string;
    row: string;
    quantity: number;
    price: number;
    orderStatus: boolean;
}

const AdminOrders: React.FC = () => {
    const statusRef = useRef<HTMLDivElement>(null);

    const [loadingOrders, setLoadingOrders] = useState(true);
    const [statusOptions, setStatusOptions] = useState<string[]>([]);
    const [filterQuery, setFilterQuery] = useState('');
    const [lastSearched, setLastSearched] = useState('');
    const [selectedOrder, setSelectedOrder] = useState<ExpandedOrder | null>(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [activeStatusPicker, setActiveStatusPicker] = useState(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [rows, setRows] = useState<RowData[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: "updatedAt",
            sort: "desc"
        }
    ]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });

    const statusFiltered = selectedStatus !== '';

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 80, disableColumnMenu: true },
        { field: 'purchaseDate', headerName: 'Order Date', width: 120, disableColumnMenu: true, renderCell: (params) => (formatDateToSlash(params.value)) },
        { field: 'userFirstName', headerName: 'First Name', width: 120, disableColumnMenu: true },
        { field: 'userLastName', headerName: 'Last Name', width: 120, disableColumnMenu: true },
        { field: 'eventName', headerName: 'Event Name', width: 200, disableColumnMenu: true },
        { field: 'eventDate', headerName: 'Event Date', width: 120, disableColumnMenu: true, renderCell: (params) => (formatDateToSlash(params.value)) },
        { field: 'price', headerName: 'Price', width: 100, disableColumnMenu: true },
        { field: 'orderStatus', headerName: 'Order Status', width: 150, disableColumnMenu: true },
    ];

    const fetchData = async (search: string, paginationModel: GridPaginationModel, sortModel: GridSortModel) => {
        setLoadingOrders(true);
        const page = paginationModel.page;
        const pageSize = paginationModel.pageSize;
        const sortBy = sortModel[0]?.field || "updatedAt";
        const order = sortModel[0]?.sort || "desc";
        const ordersDataRepsonseEither = await fetchOrdersPage(page, pageSize, search, selectedStatus, sortBy, order);
        if (isRight(ordersDataRepsonseEither)) {
            const { content, total, pageable } = ordersDataRepsonseEither.right;
            setRows(content);
            setTotalRows(total);
        } else {
            console.error(ordersDataRepsonseEither.left)
        }

        setLoadingOrders(false);
    };

    const refetchData = async () => {
        fetchData(lastSearched, paginationModel, sortModel);
    }

    useEffect(() => {
        const fetchStatusOptions = async () => {
            const statusOptionsResponseEither = await fetchOrderStatuses();
            if (isRight(statusOptionsResponseEither)) {
                setStatusOptions(statusOptionsResponseEither.right);
            } else {
                console.error(statusOptionsResponseEither.left)
            }
        }

        const handleClickOutsideStatusPicker = (e: MouseEvent) => {
            if (statusRef.current && e.target instanceof Node && !statusRef.current.contains(e.target)) {
                setActiveStatusPicker(false);
            }
        };


        fetchStatusOptions();

        document.addEventListener('mousedown', handleClickOutsideStatusPicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideStatusPicker);
        };
    }, []);

    useEffect(() => {
        fetchData(lastSearched, paginationModel, sortModel);
    }, [paginationModel, sortModel]);

    useEffect(() => {
        fetchData(lastSearched, paginationModel, sortModel);
    }, [selectedStatus]);


    const handleRowClick = async (params: GridRowParams) => {
        const responseEither = await fetchOrderById(params.row.id)
        if (isRight(responseEither)) {
            setSelectedOrder(responseEither.right);
            setOpenDialog(true);
        } else {
            console.error(responseEither.left)
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedOrder(null);
    };

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterQuery(e.target.value);
    };

    const handleFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetchData(filterQuery, paginationModel, sortModel);
        setLastSearched(filterQuery);
    }

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPaginationModel(model);
    };

    const handleSortModelChange = (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
    };

    return (
        <div className='card card-top' style={{ minHeight: 500, width: '100%' }} >
            <div className='text-l'>Orders</div>
            <div className='card-item'>
                <form onSubmit={handleFilterSubmit} className='card-item'>
                    <div className='row'>
                        <input type="text" value={filterQuery} onChange={handleQueryChange}
                            placeholder="Type to filter by id, first name, last name"
                            className='admin-search'
                        />
                        <button type="submit" className='admin-action-button'>Filter</button>
                        <div className='gap' />
                        <div className='relative' ref={statusRef}>
                            <button className={`admin-button ${statusFiltered && 'admin-filtered'}`} onClick={(() => setActiveStatusPicker(!activeStatusPicker))}>{statusFiltered ? selectedStatus : "Order Status"}</button>
                            {activeStatusPicker &&
                                <SingleSelectMenu
                                    options={statusOptions}
                                    selectedOption={selectedStatus}
                                    setSelectedOption={setSelectedStatus}
                                    closeMenu={() => setActiveStatusPicker(false)}
                                    mustSelect={false}
                                    rightAligned={true}
                                />
                            }
                        </div>
                    </div>
                </form>
            </div>
            {loadingOrders ?
                <div className="loading-spinner"></div>
                :
                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    rowCount={totalRows}
                    pageSizeOptions={[5, 10, 20]}
                    onRowClick={handleRowClick}
                    className='admin-data-grid'

                />
            }

            <Modal isOpen={openDialog} onRequestClose={handleCloseDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '1000px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '40px',
                    padding: '40px',
                    borderRadius: '15px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                <>
                    {selectedOrder && (
                        <AdminOrderModal order={selectedOrder} refetchData={refetchData} />
                    )}
                </>
            </Modal>
        </div>
    )
}

export default AdminOrders;
