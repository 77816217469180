import React, {useEffect, useRef, useState} from 'react';
import Event from '../../interfaces/Event';
import './Events.css';
import {formatDateObjToMonthAndDay} from '../../utils/DateUtils/DateUtils'
import 'react-datepicker/dist/react-datepicker.css';
import {DateRangeMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import EventBar from './EventBar';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';
import {FaTimes} from 'react-icons/fa';


interface VenueEventsProps {
    events: Event[];
    loadingEvents: boolean;
}

const VenueEvents: React.FC<VenueEventsProps> = ({events, loadingEvents }) => {
    const datePickerRef = useRef<HTMLDivElement>(null);

    const [eventsToShow, setEventsToShow] = useState<Event[]>(events);
    const [numEventsToShow, setNumEventsToShow] = useState<number>(10);
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [activeDatePicker, setActiveDatePicker] = useState(false);

    const { isMobile } = useScreenSizeContext();

    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            const firstDate: Date = dateRange[0]
            const secondDate: Date = dateRange[1]
            const adjustedEnd = new Date(secondDate);
            adjustedEnd.setDate(adjustedEnd.getDate() + 1);

            const filteredEvents = events.filter(event => {
                if (!event.occursAtLocal) {
                    return true;
                }
                const eventDate = new Date(event.occursAtLocal);
                const withinDateRange = eventDate >= firstDate && eventDate <= adjustedEnd;

                return withinDateRange
            })

            setEventsToShow(filteredEvents);
        } else {
            setEventsToShow(events);
        }
        setNumEventsToShow(10);

    }, [events, dateRange])


    useEffect(() => {
        const handleClickOutsideDatePicker = (e: MouseEvent) => {
            if (datePickerRef.current && e.target instanceof Node && !datePickerRef.current.contains(e.target)) {
                setActiveDatePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideDatePicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDatePicker);
        };
    }, []);


    const showMoreEvents = () => {
        setNumEventsToShow(prev => prev + 10);
    };

    const handleResetFilters = () => {
        setDateRange([null, null]);
    }

    const dateFiltered = dateRange[1] !== null;
    const dateRangeString = (dateRange[0] && dateRange[1]) ? formatDateObjToMonthAndDay(dateRange[0]) + " - " + formatDateObjToMonthAndDay(dateRange[1]) : '';

    return (
        <div className='container-main'>
            <div className='content-main'>
                <div className={`${isMobile ? "col" : "row"} `}>
                    <div className='row'>
                        <div className={`${isMobile ? "left text-m" : "left text-xl"} `}>Upcoming Events</div>
                    </div>
                    <br/>
                    {events.length > 0 &&
                        <div className={`${isMobile ? "row" : 'row justify-end'} `}>
                            <div className='relative' ref={datePickerRef}>
                                <button className={`filter-button ${dateFiltered && 'filtered'}`} onClick={(() => setActiveDatePicker(!activeDatePicker))}>{(dateFiltered && !isMobile) ? dateRangeString : "Date"}</button>
                                {activeDatePicker &&
                                    <DateRangeMenu
                                        startDate={dateRange[0]}
                                        endDate={dateRange[1]}
                                        onChange={(dates) => setDateRange(dates)}
                                        onClose={() => setActiveDatePicker(false)}
                                    />
                                }
                            </div>
                            {(dateFiltered) &&
                                <>
                                    <div className='gap-s' />
                                    <button className='filter-button' onClick={handleResetFilters}>
                                        {isMobile ?
                                            <>Clear Filters</>
                                            :
                                            <div className='col'>
                                                <FaTimes />
                                            </div>
                                        }
                                    </button>
                                </>
                            }
                        </div>

                    }
                    <br />
                </div>
                {loadingEvents ? (
                    <div className="loading-spinner"></div>
                ) : (
                    <div className='col'>
                        {eventsToShow.length > 0 ?
                            <>
                                {eventsToShow.slice(0, numEventsToShow).map(event => (
                                    <React.Fragment key={event.tevoEventId}>
                                        <EventBar event={event} isMobile={isMobile} />
                                        {isMobile && <div className='divider-margin' />}
                                    </React.Fragment>
                                ))}
                                {eventsToShow.length > numEventsToShow && (
                                    <div>
                                        <br />
                                        <br />
                                        <button className='filter-button no-margin' onClick={showMoreEvents}>Show More</button>
                                    </div>
                                )}
                            </>
                            :
                            <>
                                <br />
                                <br />
                                <div className='text-s'>No Upcoming Events</div>
                                <br />
                            </>
                        }
                        <br />
                    </div>
                )}
            </div>
        </div>
    )
}

export default VenueEvents;
