import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Footer from '../components/Footer/Footer';
import {fetchEventsByVenue, fetchVenueById} from '../api/endpoints';
import Event from '../interfaces/Event';
import Navbar from '../components/Navbar/Navbar';
import {isRight} from 'fp-ts/lib/Either';
import {canBeParsedToInt} from '../utils/GlobalUtils';
import Venue from '../interfaces/Venue';
import VenueHero from '../components/Heros/VenueHero';
import VenueEvents from '../components/Events/VenueEvents';
import MobileLoading from "../components/MobileModals/MobileLoading";
import {useScreenSizeContext} from "../providers/ScreenSizeProvider";


const VenuePage: React.FC = () => {
    const { venueName, venueId = '' } = useParams<{ venueName: string, venueId: string }>();
    const [venue , setVenue] = useState<Venue | null>(null);
    const [loadingVenue, setLoadingVenue] = useState<boolean>(true);

    const [events, setEvents] = useState<Event[]>([]);
    const [loadingEvents, setLoadingEvents] = useState<boolean>(true);

    const navigate = useNavigate();
    const { isMobile } = useScreenSizeContext();

    useEffect(() => {
        const fetchVenue = async () => {
            setLoadingVenue(true);
            const venueDataEither = await fetchVenueById(venueId);
            if (isRight(venueDataEither)) {
                setVenue(venueDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingVenue(false);
        }

        const fetchEvents = async () => {
            setLoadingEvents(true);
            const eventsDataEither = await fetchEventsByVenue(venueId);
            if (isRight(eventsDataEither)) {
                setEvents(eventsDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvents(false);
        };

        if (canBeParsedToInt(venueId)) {
            fetchVenue();
            fetchEvents();

        } else {
            navigate(`/not-found`);
        }
    }, [venueName, venueId]);


    return (
        <div>
            {isMobile &&
                <MobileLoading loading={loadingVenue || loadingEvents}/>
            }
            <Navbar showSearch={true}/>
            <VenueHero venue={venue} loadingVenue={loadingVenue}/>
            <VenueEvents events={events} loadingEvents={loadingEvents} />
            <Footer/>
        </div>
    )
}

export default VenuePage;
