import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {actuallyResetPassword} from '../../api/endpoints';
import {isRight} from 'fp-ts/lib/Either';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import ResetPasswordResponseCard from './ResetPasswordResponseCard';


const ResetPasswordFormCard: React.FC = () => {

    const { resetToken } = useParams<{ resetToken: string }>();
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [resetPasswordResponse, setResetPasswordResponse] = useState<any>(null);
    const [showPassword, setShowPassword] = useState(false);


    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmNewPassword(e.target.value);
    };

    const triggerPasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        if (resetToken !== undefined && newPassword.length > 8 && newPassword === confirmNewPassword) {
            const resetResponse = await actuallyResetPassword(newPassword, resetToken)
            if (isRight(resetResponse)) {
                setResetPasswordResponse(true)
            } else {
                setResetPasswordResponse(false)
                console.error(resetResponse.left)
            }
        }


        setIsLoading(false);
    }

    return (
        isLoading ?
            <div className="loading-spinner"></div>
            :
            resetPasswordResponse == null ?
                <form className='card' onSubmit={triggerPasswordReset}>
                    <div className='text-l'>Reset Your Password</div>
                    <div className='card-item'>
                        <div className='row relative'>
                            <input
                                type={showPassword ? "text" : "password"}

                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                placeholder="New Password"
                                className='input'
                            />
                            <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ?
                                    <FaEyeSlash />
                                    :
                                    <FaEye />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='card-item'>
                        <div className='row relative'>
                            <input
                                type={showPassword ? "text" : "password"}

                                value={confirmNewPassword}
                                onChange={handleConfirmNewPasswordChange}
                                placeholder="Confirm New Password"
                                className='input'
                            />
                            <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ?
                                    <FaEyeSlash />
                                    :
                                    <FaEye />
                                }
                            </div>
                        </div>
                    </div>
                    <br />
                        <div className='row'>
                            <div className={`${(newPassword.length > 0 && newPassword.length < 8) ? "text-xs-error" : "text-xs"}`}>&nbsp;Password must contain at least 8 characters</div>
                        </div>
                        <div className='row'>
                            <div className={`${(newPassword === confirmNewPassword) ? "text-xs" : "text-xs-error"}`}>&nbsp;Passwords must match</div>
                        </div>
                        <div className='divider-margin' />
                    <div className='card-item'>
                        <button className='action-button-full' type="submit">Submit</button>
                    </div>
                </form>
                :
                <ResetPasswordResponseCard success={resetPasswordResponse} />
    )
}

export default ResetPasswordFormCard;
