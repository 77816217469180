import React, {useEffect, useRef, useState} from 'react';
import {trySignUp} from '../../api/endpoints';
import {isRight} from 'fp-ts/lib/Either';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {SingleSelectMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import {useScreenSizeContext} from "../../providers/ScreenSizeProvider";
import logo from "../../assets/714-logo-hover.svg";


interface SignUpFormCardProps {
    onClickLogIn: () => void;
}

const SignUpFormCard: React.FC<SignUpFormCardProps> = ({ onClickLogIn }) => {
    const {isMobile} = useScreenSizeContext();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [activeReferralPicker, setActiveReferralPicker] = useState(false);
    const [selectedReferralOption, setSelectedReferralOption] = useState('');

    const referralRef = useRef<HTMLDivElement>(null);

    const [error, setError] = useState(false);
    const [inputErrors, setInputErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        password: false
    })
    const [signupSuccessful, setSignupSuccessful] = useState<any>(null);
    const [singupIssue, setSignupIssue] = useState<any>(null);

    const formRef = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        const handleClickOutsideReferralPicker = (e: MouseEvent) => {
            if (referralRef.current && e.target instanceof Node && !referralRef.current.contains(e.target)) {
                setActiveReferralPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideReferralPicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideReferralPicker);
        };
    }, [])


    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);

        const newInputErrors = {
            ...inputErrors,
            email: false,
        };

        setInputErrors(newInputErrors);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);

        const newInputErrors = {
            ...inputErrors,
            password: false,
        };

        setInputErrors(newInputErrors);
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);

        const newInputErrors = {
            ...inputErrors,
            firstName: false,
        };

        setInputErrors(newInputErrors);
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);

        const newInputErrors = {
            ...inputErrors,
            lastName: false,
        };

        setInputErrors(newInputErrors);
    };


    const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newInputErrors = {
            firstName: firstName === '',
            lastName: lastName === '',
            email: email === '',
            password: password.length < 8
        }

        setInputErrors(newInputErrors)

        if (formRef.current && formRef.current.checkValidity() && !(Object.values(newInputErrors).includes(true))) {
            setIsLoading(true);
            const signupResponse = await trySignUp(email, password, firstName, lastName, selectedReferralOption);
            if (isRight(signupResponse)) {
                setSignupSuccessful(true)
                setError(false);
            } else {
                setSignupSuccessful(false)
                setSignupIssue(signupResponse.left)
                setError(true);
            }
            setIsLoading(false);
        } else {
            formRef.current?.reportValidity();
        }
    }

    return (
        isLoading ?
            <div className="loading-spinner"></div>
            :
            signupSuccessful == null ?
                <form className={isMobile ? '' : 'card'} onSubmit={handleSignup} ref={formRef} noValidate>
                    <div className='text-l'>Sign Up</div>
                    <div className='card-item'>
                        <input
                            type="text"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            placeholder="First Name"
                            className={`input ${inputErrors.firstName ? 'input-error' : ''}`}
                            required
                        />
                    </div>
                    <div className='card-item'>
                        <input
                            type="text"
                            value={lastName}
                            onChange={handleLastNameChange}
                            placeholder="Last Name"
                            className={`input ${inputErrors.lastName ? 'input-error' : ''}`}
                            required
                        />
                    </div>
                    <div className='card-item'>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email"
                            className={`input ${inputErrors.email ? 'input-error' : ''}`}
                            required
                        />
                    </div>
                    <div className='card-item'>
                        <div className='row relative'>
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Password"
                                className={`input ${inputErrors.password ? 'input-error' : ''}`}
                                required
                            />
                            <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ?
                                    <FaEyeSlash/>
                                    :
                                    <FaEye/>
                                }
                            </div>
                        </div>
                        <br/>
                        <div className='row'>
                            <div
                                className={`${(password.length > 0 && password.length < 8) ? "text-xs-error" : "text-xs"}`}>&nbsp;Password
                                must contain at least 8 characters
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className='row'>
                        <div className="text-xs">&nbsp;How did you hear about us? (Optional)</div>
                    </div>
                    <div className='relative' ref={referralRef}>
                        <button style={{minWidth: "260px", marginTop: "10px", marginLeft: "3px"}}
                                className={`filter-button ${false && 'filtered'}`} type="button"
                                onClick={(() => setActiveReferralPicker(!activeReferralPicker))}>{selectedReferralOption !== '' ? selectedReferralOption : "No Selection"}</button>
                        {activeReferralPicker &&
                            <SingleSelectMenu
                                options={["Referral", "AM830 - The Sports Lodge", "Internet Search", "Email Newsletter", "Social Media", "Web Advertisement", "Yelp", "Driving / Walking By", "Podcast", "MEGA 96.3", "La Raza 97.9"]}
                                selectedOption={selectedReferralOption}
                                setSelectedOption={setSelectedReferralOption}
                                closeMenu={() => setActiveReferralPicker(false)}
                                mustSelect={false}
                                rightAligned={false}
                            />
                        }
                    </div>
                    <br/>
                    <div className='card-item'>
                        <button className='action-button-full'
                                type="submit">{isLoading ? "Loading" : "Sign Up"}</button>
                        {error &&
                            <>
                                <br/>
                                <div className='card-list'>
                                    <div className={`text-xs-error`}>
                                        Please enter valid sign up information
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-xs'>Already have an account?&nbsp;</div>
                            <div className='text-xs-link' onClick={onClickLogIn}>Log In</div>
                        </div>
                    </div>
                </form>
                :
                <div className={`${!isMobile && 'card'}`}>
                    <div className='row'>
                        <div className='left'>
                            <div className='text-l'>{signupSuccessful ? "Successfully Signed Up!" : "Issue Signing Up"}</div>
                        </div>
                        {!isMobile && <img src={logo} alt="714 Logo" style={{width: '140px', height: 'auto', marginBottom: "4px" }} />}
                    </div>
                    <div className='card-item'>
                        <div className='text-s-light'>
                            {signupSuccessful ? "Please check your email to verify your account" : singupIssue}
                        </div>
                        <br/>
                        <div className='row'>
                            <button className='action-button' onClick={onClickLogIn}>
                                Log in
                            </button>
                        </div>
                    </div>
                </div>
    )
}

export default SignUpFormCard;
